import { Button, Grid, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { watchAuthState } from '../../../services/login_services';
import { useAppDispatch } from '../../../store/store';
import { Helmet } from 'react-helmet';
import { PageType, setCurrentPage } from '../../../store/slices/general.slice';
import { useSelector } from 'react-redux';
import {
  getAllAdminUsers,
  getAllCoalitions,
} from '../../../store/thunks/admin.thunks';
import { toast } from 'react-toastify';
import { storage } from '../../../services/firebase_services';
import VideoInput from '../../components/VideoInput';
import '../../../index.css';

export function RouteBuddiesAdminNewFile() {
  const [loggedIn, setLoggedIn] = useState(false);

  const { admin } = useSelector((state: any) => state.GeneralSlice);

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [pageNumber, setPageNumber] = useState(1);

  const [images, setImages] = useState<any[]>([]);
  const [imagesChanged, setImagedChanged] = useState(false);
  const [imagesError, setImagesError] = useState('');

  const [confirmCreation, setConfirmCreation] = useState(false);
  const [source, setSource] = useState<any>();

  const [file, setFile] = useState<any>(null); // Store the actual file

  useEffect(() => {
    watchAuthState(navigate, 'other', setLoggedIn);
  }, []);

  useEffect(() => {
    dispatch(setCurrentPage(PageType.ADMIN_COMMUNITIES));
  }, []);

  useEffect(() => {
    if (admin) {
      dispatch(getAllCoalitions());
      getAllAdminUsers();
    }
  }, [admin]);

  const onChange = (imageList: any[], addUpdateIndex: any) => {
    setImagedChanged(true);
    // data for submit
    setImages(imageList);
  };

  async function verifyPageOne() {
    let numErrors = 0;

    if (!source) {
      numErrors += 1;
      setImagesError('You must select one file.');
    } else {
      setImagesError('');
    }

    if (numErrors === 0) {
      createFile();
    }
  }

  async function nextPage() {
    if (pageNumber === 1) {
      verifyPageOne();
    } else if (pageNumber === 2) {
    }
  }

  async function createFile() {
    // Update the logo if changed
    // CHanges
    if (file) {
      try {
        // ok
        // Upload the actual file object, not the URL
        const storageRef = storage.ref(
          '/files/' +
            (Math.random() * (Math.random() * Math.random())).toString()
        );
        const snapshot = await storageRef.put(file);
        const downloadURL = await snapshot.ref.getDownloadURL();
        console.log('File available at', downloadURL);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    toast.success('Community created!');

    setImages([]);
  }

  return (
    <Grid container item xs={12} sx={{ paddingTop: 0 }}>
      <Helmet>
        <meta
          name="title"
          content="RouteBuddies | The Community Safety App | Safer Together"
        />
        <meta
          name="description"
          content="Street safety, in numbers. RouteBuddies strides to make streets safer through our community driven app, established in 2022 and coming to a city near you soon."
        />
        <meta
          name="keywords"
          content="safety, safety app, routebuddies, route, buddies, app, safeguarding, jack blundell, routebuddies app, cardiff safety app, cardiff app, cardiff routebuddies, cardiff buddies, jack blundell, jack blundell cardiff, routebuddies safety, best safety app"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="RouteBuddies Limited." />
      </Helmet>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
        }}
        alignItems={'flex-start'}
      >
        {/* Title */}
        <Grid
          item
          container
          flexDirection={'column'}
          style={{
            borderBottom: '0.5px solid rgba(200,200,200,0.4)',
          }}
        >
          <Typography
            variant={'h4'}
            fontWeight={'800'}
            sx={{ marginBottom: 1 }}
          >
            New File
          </Typography>
          <Typography
            variant={'body1'}
            fontWeight={'400'}
            style={{ marginBottom: '15px' }}
          >
            Let's create a new file!
          </Typography>
        </Grid>

        {/* Page 1  - Name description, image...*/}
        {pageNumber === 1 && (
          <Grid container spacing={2} style={{ paddingTop: '15px' }}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={5}
              alignItems={'flex-start'}
              flexDirection={'column'}
            >
              <VideoInput
                source={source}
                setSource={setSource}
                file={file}
                setFile={setFile}
              />
              {imagesError !== '' && (
                <Grid
                  style={{
                    width: '100%',
                    marginBottom: 10,
                    marginTop: 5,
                    justifyContent: 'center',
                  }}
                >
                  <Typography style={{ textAlign: 'left', color: 'red' }}>
                    {imagesError}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}

        {/* Page 2 - Free / Premium */}
        {/* Will get user to pay for premium if needed */}

        {/* Page 3 - Add to coalition (Optional) */}

        {/*  Buttons */}
        <Grid
          item
          container
          xs={12}
          justifyContent={pageNumber === 1 ? 'flex-end' : 'space-between'}
          sx={{ marginBottom: 5 }}
        >
          <Button
            onClick={() => {
              nextPage();
            }}
            variant="contained"
          >
            {'Create File'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
