import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from '../../store/store';
import { AdminContainer } from '../components/AdminContainer';
import { Footer } from '../components/Footer';
import { AdminHome } from './AdminHome';
import { AppTermsAndConditions } from './AppTermsAndConditions';
import Blogs from './Blogs';
import Contact from './Contact';
import Home from './Home';

import { PrivacyPolicy } from './PrivacyPolicy';
import Reports from './Reports';
import { TermsAndConditions } from './TermsAndConditions';
import Users from './Users';
import Verifications from './Verifications';
import News from './News';
import Register from './Register';
import { Commmunities } from './Communities';
import { UserContainer } from '../components/UserContainer';
import { CommunityAdminContainer } from '../components/community_admin/CommunityAdminContainer';
import { CommunityAdminHome } from './community_admin/CommunityAdminHome';
import { CommunityAdminUsers } from './community_admin/CommunityAdminUsers';
import { ViewUserScreen } from '../components/community_admin/ViewUserScreen';
import { ViewActivityScreen } from '../components/community_admin/ViewActivityScreen';
import { NewActivityScreen } from './community_admin/NewActivityScreen';
import Venues from './Venues';
import { NewMembersScreen } from './community_admin/NewMembersScreen';
import { CommunityAdminActivites } from './community_admin/CommunityAdminActivities';
import { RouteBuddiesAdminHome } from './routebuddies_admin/RouteBuddiesAdminHome';
import { RouteBuddiesAdminActivites } from './routebuddies_admin/RouteBuddiesAdminActivities';
import { RouteBuddiesAdminUsers } from './routebuddies_admin/RouteBuddiesAdminUsers';
import { RouteBuddiesNewActivityScreen } from './routebuddies_admin/RouteBuddiesNewActivityScreen';
import { LoginScreen } from './LoginScreen';
import { RouteBuddiesNewCommunityAdmin } from './routebuddies_admin/RouteBuddiesNewCommunityAdmin';
import { ViewPublicTrips } from './ViewPublicTrips';
import { RouteBuddiesAdminCommunities } from './routebuddies_admin/RouteBuddiesAdminCommunities';
import { RouteBuddiesAdminNewCommunity } from './routebuddies_admin/RouteBuddiesAdminNewCommunity';
import { CommunityAdminInvite } from './community_admin/CommunityAdminInvite';
import VSNI from './VSNI';
import { RouteBuddiesAdminViewCommunity } from './routebuddies_admin/RouteBuddiesAdminViewCommunity';
import { CommunitySettingsScreen } from './community_admin/CommunitySettingsScreen';
import { RouteBuddiesAdminNewFile } from './routebuddies_admin/RouteBuddiesAdminNewFile';
import { AppleJSON } from './AppleJSON';
import { Grid, Typography, Button } from '@mui/material';
import DeleteAccount from './DeleteAccount';
export default function AppRoutes({ theme, mapboxgl }: any) {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <Register theme={theme} />
                <Footer />
              </>
            }
          />
          <Route
            path="/blogs/:blogId"
            element={
              <>
                <Blogs theme={theme} />
                <Footer />
              </>
            }
          />
          <Route
            path="/public_trips/:communityId"
            element={
              <UserContainer>
                <ViewPublicTrips mapboxgl={mapboxgl} />
                <Footer />
              </UserContainer>
            }
          />
          <Route
            path="/blogs/"
            element={
              <>
                <Blogs theme={theme} />
                <Footer />
              </>
            }
          />
          <Route
            path="/communities/"
            element={
              <>
                <Commmunities />
                <Footer />
              </>
            }
          />
          <Route
            path="/admin"
            element={
              <AdminContainer>
                <AdminHome />
                <Footer />
              </AdminContainer>
            }
          />
          <Route
            path="/vsni"
            element={
              <>
                <VSNI theme={theme} />
                <Footer />
              </>
            }
          />
          <Route
            path="/enterprise"
            element={
              <>
                <Venues theme={theme} />
                <Footer />
              </>
            }
          />
          <Route
            path="/termsandconditions"
            element={
              <>
                <TermsAndConditions />
                <Footer />
              </>
            }
          />
          <Route
            path="/apptermsandconditions"
            element={
              <>
                <AppTermsAndConditions />
              </>
            }
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route
            path="/login"
            element={
              <>
                <LoginScreen loginPage={true} />
                <Footer />
              </>
            }
          />
          <Route
            path="/reports"
            element={
              <AdminContainer>
                <>
                  <Reports theme={theme} mapboxgl={mapboxgl} />
                  <Footer />
                </>
              </AdminContainer>
            }
          />
          <Route
            path="/users"
            element={
              <AdminContainer>
                <>
                  <Users theme={theme} mapboxgl={mapboxgl} />
                  <Footer />
                </>
              </AdminContainer>
            }
          />
          <Route
            path="/verifications"
            element={
              <AdminContainer>
                <>
                  <Verifications theme={theme} mapboxgl={mapboxgl} />
                  <Footer />
                </>
              </AdminContainer>
            }
          />
          <Route
            path="/contact"
            element={
              <>
                <Contact theme={theme} mapboxgl={mapboxgl} />
                <Footer />
              </>
            }
          />
          <Route
            path="/deleteAccount"
            element={
              <>
                <DeleteAccount theme={theme} mapboxgl={mapboxgl} />
                <Footer />
              </>
            }
          />
          <Route
            path="/news"
            element={
              <>
                <News theme={theme} mapboxgl={mapboxgl} />
                <Footer />
              </>
            }
          />
          <Route
            path="/404"
            element={
              <main style={{ padding: '1rem' }}>
                <p>That page doesn't exist, ahh! </p>
              </main>
            }
          />
          <Route
            path="/enterprise/dashboard"
            element={
              <CommunityAdminContainer>
                <CommunityAdminHome />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/enterprise/activities"
            element={
              <CommunityAdminContainer>
                <CommunityAdminActivites />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/enterprise/members"
            element={
              <CommunityAdminContainer>
                <CommunityAdminUsers />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/enterprise/members/:id"
            element={
              <CommunityAdminContainer>
                <ViewUserScreen />
              </CommunityAdminContainer>
            }
          />{' '}
          <Route
            path="/enterprise/activities/:id"
            element={
              <CommunityAdminContainer>
                <ViewActivityScreen mapboxgl={mapboxgl} />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/enterprise/invite/:id"
            element={
              <UserContainer>
                <CommunityAdminInvite />
              </UserContainer>
            }
          />
          <Route
            path="/enterprise/reports"
            element={
              <CommunityAdminContainer>
                <CommunityAdminHome />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/enterprise/activities/new"
            element={
              <CommunityAdminContainer>
                <NewActivityScreen />
              </CommunityAdminContainer>
            }
          />{' '}
          <Route
            path="/enterprise/members/new"
            element={
              <CommunityAdminContainer>
                <NewMembersScreen />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/enterprise/settings/"
            element={
              <CommunityAdminContainer>
                <CommunitySettingsScreen />
              </CommunityAdminContainer>
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              <AdminContainer>
                <RouteBuddiesAdminHome />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/communities"
            element={
              <AdminContainer>
                <RouteBuddiesAdminCommunities />
              </AdminContainer>
            }
          />{' '}
          <Route
            path="/admin/communities/:id"
            element={
              <AdminContainer>
                <RouteBuddiesAdminViewCommunity />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/communities/new"
            element={
              <AdminContainer>
                <RouteBuddiesAdminNewCommunity />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/files/new"
            element={
              <AdminContainer>
                <RouteBuddiesAdminNewFile />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/activities"
            element={
              <AdminContainer>
                <RouteBuddiesAdminActivites />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/activities/new"
            element={
              <AdminContainer>
                <RouteBuddiesNewActivityScreen />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/community_admins/new"
            element={
              <AdminContainer>
                <RouteBuddiesNewCommunityAdmin />
              </AdminContainer>
            }
          />
          <Route
            path="/admin/members"
            element={
              <AdminContainer>
                <RouteBuddiesAdminUsers />
              </AdminContainer>
            }
          />{' '}
          <Route
            path="/admin/members/:id"
            element={
              <AdminContainer>
                <RouteBuddiesAdminUsers />
              </AdminContainer>
            }
          />
          <Route path="/communities/:id" element={<></>} />
          <Route path="/activities/:id" element={<></>} />
          <Route
            path="*"
            element={
              <Grid
                container
                item
                xs={12}
                justifyContent={'center'}
                alignItems={'center'}
                height={'100vh'}
              >
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  rowSpacing={2}
                >
                  <Grid item>
                    <img src={'/static/searching.png'} width={400} />
                  </Grid>
                  <Grid item>
                    <Typography variant={'h4'}>You goofed up.</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body1'}>
                      This page LITERALLY doesn't exist - This is not a big foot
                      situation, your search can stop.
                    </Typography>
                  </Grid>
                  <></>
                  <Grid item>
                    <Button
                      variant={'contained'}
                      onClick={() => {
                        window.location.href = '/login';
                      }}
                    >
                      Take me home!
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Routes>
      </Provider>
    </BrowserRouter>
  );
}
