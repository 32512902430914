import React from 'react';

export default function VideoInput({
  source,
  setSource,
  file,
  setFile,
  ...props
}: any) {
  const { width, height } = props;

  const inputRef = React.useRef<any>();

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    const url = URL.createObjectURL(selectedFile);
    setSource(url); // Still set the URL for preview
    setFile(selectedFile); // Keep the file object for uploading
  };

  const handleChoose = (event: any) => {
    inputRef.current.click();
  };

  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".mov,.mp4,.jpg,.png"
      />
      {!source && <button onClick={handleChoose}>Choose</button>}
      {source && (
        <video
          className="VideoInput_video"
          width="100%"
          height={height}
          controls
          src={source}
        />
      )}
      <div className="VideoInput_footer">{source || 'Nothing selected'}</div>
    </div>
  );
}
